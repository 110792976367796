import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ContentInfo.style";
import Typography from "../../../../common/Typography/Typography";
import ContentHeader from "../ContentHeader/ContentHeader";
import Link from "../../../../common/Link/Link";
import { EVENTS_BLOCK } from "../../../../utils/constants/homepageConstants";
import { getTextFromHtml } from "../../../../utils/helper";

const ContentInfo = ({
  content,
  blockClass,
  asContentHeader,
  forSingleCard,
}) => {
  const classes = useStyles();

  switch (blockClass) {
    case EVENTS_BLOCK:
      return (
        <div className={classes.event_information_container}>
          <Typography
            level="t4_text_4"
            className={classes.event_information_starts}
          >
            {content.starts}
          </Typography>
          <Typography
            level="t4_text_4"
            className={classes.event_information_time}
          >
            {content.start_time} - {content.end_time}
          </Typography>

          <Link
            children={
              <Typography
                level={forSingleCard ? "t4_text_4" : "t1_text_1"}
                className={classes.event_information_description}
              >
                {getTextFromHtml(content.description)}
              </Typography>
            }
            url={content.event_url}
            className={classes.content_link}
          />
        </div>
      );

    default:
      return asContentHeader ? (
        <ContentHeader content={content} blockClass={blockClass} />
      ) : (
        <Link
          children={
            <Typography
              level={forSingleCard ? "t4_text_4" : "t1_text_1"}
              className={classes.content_description}
            >
              {getTextFromHtml(content.truncated_description)}
            </Typography>
          }
          url={content.url_content}
          className={classes.content_link}
        />
      );
  }
};

ContentInfo.propTypes = {
  content: PropTypes.object.isRequired,
  blockClass: PropTypes.string,
  asContentHeader: PropTypes.bool,
  forSingleCard: PropTypes.bool,
};

ContentInfo.defaultProps = {
  asContentHeader: false,
  forSingleCard: false,
};

export default ContentInfo;
