import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    content_header: {
      paddingBottom: theme.spacing(1),
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      display: "block",
      "& span": {
        fontWeight: 700,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      "&:hover": {
        textDecoration: "underline !important",
        textDecorationColor: `${theme.palette.dark.black} !important`,
      },
    },
    content_header_single: {
      paddingBottom: theme.spacing(2),
      display: "block",
      "& span": {
        fontWeight: 700,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        lineHeight: "30px",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      "&:hover": {
        textDecoration: "underline !important",
        textDecorationColor: `${theme.palette.dark.black} !important`,
      },
    },
    obituary_header: {
      width: "100%",
      display: "block",
      margin: "0px !important",
    },
  };
});

export { useStyles };
