import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Link from "../../../../common/Link/Link";
import { useStyles } from "./ContentHeader.style";
import { useTown } from "../../../../context/TownContext";
import {
  CLASSIFIED_BLOCK,
  EVENTS_BLOCK,
  MILESTONES_BLOCK,
  OBITUARIES_BLOCK,
  REAL_ESTATE_BLOCK,
} from "../../../../utils/constants/homepageConstants";
import { parseEventMedia } from "../../../../utils/helpers/mediaHelper";
import { currencyFormatter, getTextFromHtml } from "../../../../utils/helper";

const ContentHeader = ({ content, blockClass, forSingleCard }) => {
  const classes = useStyles();
  const { town } = useTown();

  const RealEstateHeader = () => {
    return (
      <Link
        level={forSingleCard ? "t5_text_5" : "paragraph_1"}
        children={
          currencyFormatter.format(content.price) +
          " | " +
          content.bedrooms +
          " Bed | " +
          content.bathrooms +
          " Bath"
        }
        url={content.url_content}
        className={classes.content_header}
      />
    );
  };

  const ObituaryHeader = () => {
    const datesForObituary = () => {
      if (content.date_of_birth && content.date_of_death) {
        return content.date_of_birth + " - " + content.date_of_death;
      }

      if (content.date_of_birth) {
        return content.date_of_birth;
      }

      if (content.date_of_death) {
        return content.date_of_death;
      }

      return null;
    };

    return (
      <>
        <Link
          level={forSingleCard ? "t5_text_5" : "paragraph_1"}
          children={content.name}
          url={content.url_content}
          className={clsx(classes.content_header, classes.obituary_header)}
        />
        {datesForObituary() && (
          <Link
            level="paragraph_2"
            children={datesForObituary()}
            url={content.url_content}
            className={clsx(classes.content_header, classes.obituary_header)}
          />
        )}
      </>
    );
  };

  const EventHeader = () => {
    const { eventUrl } = parseEventMedia({
      event: content,
      town: town,
    });

    return (
      <Link
        level={forSingleCard ? "t5_text_5" : "paragraph_1"}
        children={content.title}
        url={eventUrl}
        className={classes.content_header}
      />
    );
  };

  const ClassifiedOrMilestoneHeader = () => {
    return (
      <Link
        level={forSingleCard ? "t5_text_5" : "paragraph_1"}
        children={getTextFromHtml(content.name)}
        url={content.url_content}
        className={classes.content_header}
      />
    );
  };

  switch (blockClass) {
    case REAL_ESTATE_BLOCK:
      return <RealEstateHeader />;
    case OBITUARIES_BLOCK:
      return <ObituaryHeader />;
    case EVENTS_BLOCK:
      return <EventHeader />;
    case CLASSIFIED_BLOCK:
    case MILESTONES_BLOCK:
      return <ClassifiedOrMilestoneHeader />;
    default:
      return (
        <Link
          level={forSingleCard ? "t5_text_5" : "medium_13_px"}
          children={getTextFromHtml(content.name)}
          url={content.url_content}
          className={
            forSingleCard
              ? classes.content_header_single
              : classes.content_header
          }
        />
      );
  }
};

ContentHeader.propTypes = {
  content: PropTypes.object.isRequired,
  blockClass: PropTypes.string,
  forSingleCard: PropTypes.bool,
};

ContentHeader.defaultProps = {
  forSingleCard: false,
};

export default ContentHeader;
