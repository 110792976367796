import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    event_information_description: {
      fontWeight: 400,
      fontSize: theme.spacing(2),
      width: "100%",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: "5",
      WebkitBoxOrient: "vertical",
    },
    event_information_starts: {
      fontSize: theme.spacing(1.75),
      width: "100%",
      display: "block",
      fontWeight: 600,
    },
    event_information_time: {
      fontSize: theme.spacing(1.75),
      width: "100%",
      display: "block",
      paddingBottom: theme.spacing(1),
      fontWeight: 600,
    },
    content_header: {
      "& span": {
        lineHeight: "28px",
        fontWeight: 600,
      },
    },
    content_description: {
      fontWeight: 400,
      fontSize: theme.spacing(2),
    },
    content_link: {
      "& span": {
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    event_information_container: {
      padding: "0px 8px 8px 8px",
    },
  };
});

export { useStyles };
