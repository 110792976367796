import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    details_root: {
      justifyContent: "space-between",
      [theme.breakpoints.down(960)]: {
        flexWrap: "wrap",
        padding: "0px !important",
      },
    },
    left_block_container: {
      marginRight: theme.spacing(8),
      [theme.breakpoints.down(960)]: {
        marginRight: 0,
      },
    },
    title_block_container: {
      borderBottom: `1px solid ${theme.palette.grayVariation.grayForBorder}`,
      marginBottom: theme.spacing(3),
    },
    title_ad_container: {
      marginBottom: theme.spacing(3),
    },

    content_header: {
      padding: 0,
      "& h3": {
        lineHeight: "28px",
        fontWeight: 600,
      },
    },
    content_card: {
      width: "100%",
      marginBottom: 0,
      padding: theme.spacing(2),
      [theme.breakpoints.down(768)]: {
        padding: 0,
      },
    },
    margin_bottom_without_ads: {
      marginBottom: theme.spacing(2),
    },
    image: {
      maxHeight: theme.spacing(25),
      [theme.breakpoints.down(768)]: {
        maxHeight: theme.spacing(31.25),
      },
    },
    media_container: {
      height: theme.spacing(25),
      maxHeight: theme.spacing(25),
      [theme.breakpoints.down(768)]: {
        height: theme.spacing(31.25),
        maxHeight: theme.spacing(31.25),
      },
    },
    no_rounded_border: {
      borderRadius: "0px",
    },
    padding_16: {
      padding: theme.spacing(2),
    },
    ads_container: {
      width: "100%",
      display: "flex",
      justifyContent: (singleAd) => (singleAd ? "center" : "space-evenly"),
      marginBottom: theme.spacing(2),
    },
    actions_main_container: {
      padding: `${theme.spacing(1)}px 0px`,
      "& [class*='actions_container']": {
        justifyContent: "center",
      },
    },
    read_more_section_container: {
      textAlign: "right",
    },
    link: {
      border: `1px solid ${theme.palette.grayVariation.grayForBorders} !important`,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px !important`,
      textTransform: "uppercase",
      "& span": {
        fontWeight: 700,
      },
      "&:hover": {
        backgroundColor: theme.palette.grayVariation.grayHoverButton,
      },
    },
  };
});

export { useStyles };
